import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { usePermissions } from '@common/providers';
import { DecoratedComment } from '@api/member.models';
import { ItemList, EmptyList } from '@common/components';
import { CommentsListItem } from '../../components';

interface CommentsListProps {
  isRTL?: boolean;
  isMobile?: boolean;
  isLoading?: boolean;
  comments?: DecoratedComment[];
  handleOpenItem: (comment: DecoratedComment) => void;
  handleDeleteItem: (commentData: DecoratedComment) => void;
  handleCopyItemLink: (commentUrl: string) => void;
}

const CommentsList: FC<CommentsListProps> = ({
  isRTL,
  isMobile,
  isLoading,
  comments = [],
  handleDeleteItem,
  handleCopyItemLink,
  handleOpenItem,
}) => {
  const { t } = useTranslation();
  const { can } = usePermissions();

  if (!isLoading && comments.length === 0) {
    return (
      <EmptyList
        title={t('no-comments.title')}
        subTitle={t('no-comments.content')}
      />
    );
  }

  return (
    <div>
      <ItemList
        isMobile={isMobile}
        isLoading={isLoading}
        data={comments || []}
        renderItem={comment => {
          const canDeleteComment = can('delete', 'comment', comment);

          return (
            <CommentsListItem
              isRTL={isRTL}
              isMobile={isMobile}
              data={comment}
              handleDeleteItem={canDeleteComment ? handleDeleteItem : undefined}
              handleCopyItemLink={handleCopyItemLink}
              handleOpenItem={handleOpenItem}
            />
          );
        }}
      />
    </div>
  );
};

export default CommentsList;
